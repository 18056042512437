import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommunityRoutingModule } from './community-routing.module';
import { AcceptableUseComponent } from './acceptable-use/acceptable-use.component';
import { CommunityGuidlinesComponent } from './community-guidlines/community-guidlines.component';
import { ConductComponent } from './conduct/conduct.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ButtonsModule } from '@sparro/app/shared/components/buttons/buttons.module';
import { CardsModule } from '@sparro/app/shared/components/cards/cards.module';
import { ImagesModule } from '@sparro/app/shared/components/images/images.module';
import { LayoutModule } from '@sparro/app/shared/components/layout/layout.module';
import { PipesModule } from '@sparro/app/shared/pipes/pipes.module';
import { LoaderModule } from '@sparro/app/shared/components/loader/loader.module';
import { SharedModule } from '@about/app/shared/shared.module';
import { MaterialModule } from '@sparro/app/material.module';

@NgModule({
  declarations: [AcceptableUseComponent, CommunityGuidlinesComponent, ConductComponent],
  imports: [
    CommonModule,
    CommunityRoutingModule,
    FlexLayoutModule,
    SharedModule,
    ImagesModule,
    LayoutModule,
    ButtonsModule,
    CardsModule,
    LoaderModule,
    MaterialModule,
    PipesModule
  ]
})
export class CommunityModule {}
