import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

@Component({
  selector: 'app-acceptable-use',
  templateUrl: './acceptable-use.component.html',
  styleUrls: ['./acceptable-use.component.scss']
})
export class AcceptableUseComponent implements OnInit {
  smallView: boolean = false;
  expanded: boolean = false;

  sections = [
    {
      text: `Rules for use of UNI`,
      id: `1`,
      subsections: [
        {
          text: `Engage with others in a constructive way`,
          id: `1.1`
        },
        {
          text: `No hate speech`,
          id: `1.2`
        },
        {
          text: `No harassment and bullying`,
          id: `1.3`
        },
        {
          text: `Identity and deceptive activity`,
          id: `1.4`
        },
        {
          text: `Intellectual property and personal rights`,
          id: `1.5`
        },
        {
          text: `No spam`,
          id: `1.6`
        },
        {
          text: `No malicious or illegal activity`,
          id: `1.7`
        },
        {
          text: `Abide by other UNI policies`,
          id: `1.8`
        }
      ]
    }
  ];

  constructor(public breakpointObserver: BreakpointObserver, public metaService: MetaService) {}

  ngOnInit(): void {
    this.initializeBreakpoints();
    this.updateMetaInfo();
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  toTop(id: string) {
    var element = document.getElementById(id);
    var headerOffset = this.smallView ? 250 : 150;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    this.expanded = false;
  }

  updateMetaInfo(): void {
    const title = 'Acceptable use policy - UNI';
    const description = `UNI enables creative people to share their work, get constructive 
      feedback, discuss ideas and create new knowledge to make us more creative and 
      innovative.`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/logo/uni/uni_logo_393x393.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
