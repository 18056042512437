<div class="main-container margin-auto" fxLayoutAlign="center stretch">
  <ng-container *ngIf="smallView; else largerView">
    <div class="sidenav-container">
      <div class="sidenav">
        <mat-expansion-panel class="expansion-panel mat-elevation-z0" [(expanded)]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="title margin-0 font-bold">Contents</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let section of sections">
            <div class="margin-top-2">
              <ng-container *ngIf="section?.subsections; else defaultView">
                <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      class="margin-0 section sparro-grey-800"
                      (click)="toTop(section.id)">
                      {{ section.text }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let subsection of section.subsections">
                    <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                      {{ subsection.text }}
                    </p>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
              <ng-template #defaultView>
                <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                  {{ section.text }}
                </p>
              </ng-template>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </ng-container>

  <ng-template #largerView>
    <div class="sidenav-container relative">
      <div class="sidenav">
        <p class="title margin-0 font-bold section-border">Contents</p>
        <ng-container *ngFor="let section of sections">
          <div class="section-border margin-top-2">
            <ng-container *ngIf="section?.subsections; else defaultView">
              <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title
                    class="margin-0 section sparro-grey-800"
                    (click)="toTop(section.id)">
                    {{ section.text }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let subsection of section.subsections">
                  <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                    {{ subsection.text }}
                  </p>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #defaultView>
              <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                {{ section.text }}
              </p>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div fxLayout="column" class="margin-9" fxLayoutGap="24px">
    <div class="padding-bottom-3 breadcrumb-section">
      <p class="margin-0 desc">
        <a href="https://uni.xyz/policy" class="no-decor-hover">Platform Policies</a> / Business /
        <span class="font-bold">Acceptable use policy</span>
      </p>
    </div>
    <div class="detail-section margin-top-9">
      <div fxLayout="column" fxLayoutGap="80px">
        <div fxLayout="column" fxLayoutGap="40px">
          <h1 class="margin-0">Acceptable use policy</h1>
          <p class="margin-0 desc">Effective from August 20, 2022</p>
        </div>
        <p class="margin-0 desc">
          <span class="font-bolder"
            >UNI enables creative people to share their work, get constructive feedback, discuss
            ideas and create new knowledge to make us more creative and innovative.</span
          >
          For this everyone on UNI will need to feel safe about their intellectual properties,
          designs, and other creative contents. We feel if people don’t feel safe about conversing
          in a domain they cannot contribute to the system or give their point of view. The
          following rules help everyone on UNI to have a safer and better experience:
        </p>

        <div fxLayout="column" fxLayoutGap="48px" id="1">
          <p class="margin-0 title font-bold">1. Rules for use of UNI</p>
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
            <i class="icon-info sparro-orange-800"></i>
            <i class="desc"
              >Rules formulated for UNI to help everyone on UNI to have a safe experience.</i
            >
          </div>
          <p class="margin-0 desc">
            UNI enables creative people to share their work, get feedback, discuss ideas and create
            new knowledge to take innovation and creativity among us to the next level. For this
            everyone on UNI will need to feel safe about their intellectual properties, designs, and
            other creative content. We feel if people don’t feel safe about conversing in a domain
            they cannot contribute to the system or give their point of view. The following rules
            help everyone on UNI to have a safe experience.
          </p>
          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="1.1">
              <p class="subtitle margin-0 font-bold">
                1.1 Engage with others in a constructive way
              </p>
              <p class="margin-0 desc">
                Assume everyone on UNI is here to make it a great resource with diverse backgrounds,
                beliefs, and opinions. It is OK to disagree, but please be civil, respectful, and
                considerate to other posters.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.2">
              <p class="subtitle margin-0 font-bold">1.2 No hate speech</p>
              <p class="margin-0 desc">
                UNI is a place for civil discourse and does not tolerate content that attacks or
                disparages an individual or group based on race, gender, religion, nationality,
                ethnicity, political group, sexual orientation, or another similar characteristic.
                Any generalizations about these topics should be phrased as neutrally as possible.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.3">
              <p class="subtitle margin-0 font-bold">1.3 No harassment and bullying</p>
              <p class="margin-0 desc">
                Abusive behavior directed towards private individuals is not allowed. Repeated and
                unwanted contact constitutes a form of harassment. Content should not make threats
                against others or advocate violence, including self-harm.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.4">
              <p class="subtitle margin-0 font-bold">1.4 Identity and deceptive activity</p>
              <p class="margin-0 desc">
                Your UNI profile should use your real name or if you are using representing a
                verified business it should be a legally incorporated business name and use accurate
                and truthful credentials. Do not use UNI to impersonate another person, act as
                another entity without authorization, or create multiple accounts.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.5">
              <p class="subtitle margin-0 font-bold">
                1.5 Intellectual property and personal rights
              </p>
              <p class="margin-0 desc">
                Do not post content that infringes any intellectual property or personal right of
                another party. Images, videos, projects or writing taken from another source should
                be properly attributed referenced and block quoted. Due credentials should be given
                to all the content posted on UNI.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.6">
              <p class="subtitle margin-0 font-bold">1.6 No spam</p>
              <p class="margin-0 desc">Do not use UNI to post or attract spam.</p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.7">
              <p class="subtitle margin-0 font-bold">1.7 No malicious or illegal activity</p>
              <p class="margin-0 desc">
                Do not contribute viruses, malware, and other malicious programs or engage in
                activity that harms or interferes with the operation of UNI or seeks to circumvent
                security or authentication measures. Do not use UNI to engage in an unlawful
                activity or to promote illegal acts.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.8">
              <p class="subtitle margin-0 font-bold">1.8 Abide by other UNI policies</p>
              <p class="margin-0 desc">
                Follow UNI’s other policies, which UNI may add or change from time to time.
              </p>
              <p class="margin-0 desc">
                If you see something on UNI that you believe violates our guidelines or policies,
                please report it to us using the in-product reporting tool, or via our contact form.
              </p>
              <p class="margin-0 desc">
                Violating the Acceptable Use Policy may result in the removal of content, or the
                limitation or termination of a user’s access to UNI.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-divider></mat-divider>
