<div class="main-container margin-auto" fxLayoutAlign="center stretch">
  <ng-container *ngIf="smallView; else largerView">
    <div class="sidenav-container">
      <div class="sidenav">
        <mat-expansion-panel class="expansion-panel mat-elevation-z0" [(expanded)]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="title margin-0 font-bold">Contents</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let section of sections">
            <div class="margin-top-2">
              <ng-container *ngIf="section?.subsections; else defaultView">
                <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      class="margin-0 section sparro-grey-800"
                      (click)="toTop(section.id)">
                      {{ section.text }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let subsection of section.subsections">
                    <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                      {{ subsection.text }}
                    </p>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
              <ng-template #defaultView>
                <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                  {{ section.text }}
                </p>
              </ng-template>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </ng-container>

  <ng-template #largerView>
    <div class="sidenav-container relative">
      <div class="sidenav">
        <p class="title margin-0 font-bold section-border">Contents</p>
        <ng-container *ngFor="let section of sections">
          <div class="section-border margin-top-2">
            <ng-container *ngIf="section?.subsections; else defaultView">
              <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title
                    class="margin-0 section sparro-grey-800"
                    (click)="toTop(section.id)">
                    {{ section.text }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let subsection of section.subsections">
                  <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                    {{ subsection.text }}
                  </p>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #defaultView>
              <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                {{ section.text }}
              </p>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div fxLayout="column" class="margin-9" fxLayoutGap="24px">
    <div class="padding-bottom-3 breadcrumb-section">
      <p class="margin-0 desc">
        <a href="https://uni.xyz/policy" class="no-decor-hover">Platform Policies</a> / Business /
        <span class="font-bold">Community guidelines</span>
      </p>
    </div>
    <div class="detail-section margin-top-9">
      <div fxLayout="column" fxLayoutGap="80px">
        <div fxLayout="column" fxLayoutGap="40px">
          <h1 class="margin-0">Community guidelines</h1>
          <p class="margin-0 desc">Effective from August 20, 2022</p>
        </div>
        <p class="margin-0 desc">
          <span class="font-bolder"
            >UNI is a company that aims to put its users first when it comes to making important
            decisions.
          </span>
          It’s a place where creative people showcase their work, converse, share ideas and discuss
          it with the rest of the community. To ensure these discussions help our users be more
          productive, propagate ideas among the community, and plant the seed of innovation among
          each other, UNI has put in place Community Guidelines outlining the types of discussions
          and content that are acceptable on UNI, and what may be deemed inappropriate and stopped
          by UNI. These Community Guidelines are intended to provide our users guidance and
          complement the Conduct Policy found in the User Agreement governing your use of, and
          participation in, UNI’s online services.
        </p>

        <div fxLayout="column" fxLayoutGap="48px" id="1">
          <p class="margin-0 title font-bold">1. Rules for making great community</p>
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
            <i class="icon-info sparro-orange-800"></i>
            <i class="desc">On UNI do not provide misleading information about yourself.</i>
          </div>
          <div fxLayout="column" fxLayoutGap="40px">
            <div fxLayout="column" fxLayoutGap="16px" id="1.1">
              <p class="subtitle margin-0 font-bold">1.1 Be real</p>
              <p class="margin-0 desc">
                To create an account, you provide data including your name and email address. We may
                collect additional data from social logins. We collect information directly from
                individuals, from third parties, and automatically through the UNI.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.2">
              <p class="subtitle margin-0 font-bold">1.2 Be respectful</p>
              <p class="margin-0 desc">
                When you create your profile on UNI, we collect your name, contact information, and
                other information you provide, such as your area of interest, your education, work
                experience, skills, photo, city or area, etc. Your name, photo, and any other
                information that you choose to add to your public-facing profile will be available
                for viewing to users on UNI. Once you activate a profile, other users will be able
                to see in your profile certain information about your activity on UNI, such as a
                project you published, ideas you discussed, discussions that you initiated, your
                followers and who you follow, topics of interest to you, and the information you
                list as credentials. For more information about your choices for publicly displayed
                information, see the section below about Your choices.
              </p>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              class="info-section">
              <i class="icon-info sparro-orange-800"></i>
              <i class="desc">Upload and publish the works that only you have created.</i>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.3">
              <p class="subtitle margin-0 font-bold">1.3 Upload your own work</p>
              <p class="margin-0 desc">
                We collect and store the information and content that you post on UNI, including
                your projects, ideas, discussions, and comments. Your Content, date and time stamps,
                and all associated comments are publicly viewable on UNI, along with your name. This
                also may be indexed by search engines and be republished elsewhere on the Internet
                in accordance with our Terms of Service. For more information about what you can
                change, see the below section on Your Choices.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.4">
              <p class="subtitle margin-0 font-bold">1.4 Constructive criticism</p>
              <p class="margin-0 desc">
                When you communicate with us (via email, phone, through UNI, subscription on our
                website, or otherwise), we may maintain a record of your communication and start a
                conversation if required.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.5">
              <p class="subtitle margin-0 font-bold">1.5 Stay on-topic</p>
              <p class="margin-0 desc">
                If you elect to connect your UNI account to another online service provider, such as
                a social networking service (“Integrated Service Provider”), you will be allowing us
                to pass to and receive from the Integrated Service Provider your log-in information
                and other user data. You may elect to sign in or sign up on UNI through a linked
                network like Facebook or Google (each a “Linked Network”). If you elect to sign up
                through a Linked Network, you will be asked to enable sharing of your contacts with
                UNI. If you agree to this sharing, to facilitate a more connected experience, we may
                send notices informing your contacts you have joined UNI so that you and your
                contacts can find and follow each other on UNI. The specific information we may
                collect varies by Integrated Service Provider, but the permissions page for each
                will describe the relevant information. Integrated Service Providers control how
                they use and share your information; you should consult their respective Privacy
                policies for information about their practices.
              </p>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              class="info-section">
              <i class="icon-info sparro-orange-800"></i>
              <i class="desc"
                >Do not create an organization page that you do not officially represent.</i
              >
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.6">
              <p class="subtitle margin-0 font-bold">1.6 Be Nice</p>
              <p class="margin-0 desc">
                UNI shouldn’t be used to harm others. Terrorists and those who engage in violent
                crimes are not welcome on UNI. It is not okay to use UNI’s services to harass,
                abuse, or send other unwelcomed communications to people (e.g., junk mail, spam,
                chain letters, phishing schemes). Do not use UNI’s services to promote or threaten
                violence or property damage, or for hate speech acts like attacking people because
                of their race, ethnicity, national origin, gender, sexual orientation, political or
                religious affiliations, or medical or physical condition. Also, don’t use UNI’s
                services to intentionally infect others with viruses, worms, or other software that
                can destroy or interrupt their data or computer devices. It is not okay to interfere
                with or disrupt UNI’s service.
              </p>
            </div>

            <div fxLayout="column" fxLayoutGap="16px" id="1.7">
              <p class="subtitle margin-0 font-bold">
                1.7 Put creative work not suitable for adults behind the filter (or share it
                privately)
              </p>
              <p class="margin-0 desc">
                UNI is all about helping creative people get exposure for their work, and our “Adult
                filter” is in this spirit as well. Many countries, schools, and companies block
                websites that don’t offer safe browsing so, by offering and enforcing this feature,
                we keep your work available to as broad of an audience as possible.
              </p>
              <ul>
                <li class="desc margin-bottom-4">
                  Your creative work is “Safe” if: It is appropriate for an audience of all ages and
                  geographies, and you wouldn’t get in trouble for looking at it at work. Anything
                  safe can be seen by everyone everywhere, whether they’re registered with UNI or
                  not.
                </li>
                <li class="desc margin-bottom-4">
                  Your creative work should go behind the adult filter If: It contains nudity,
                  expletives, violence or any other material making it unsuitable for a younger
                  audience, a global audience or people at work. Content behind the adult filter is
                  available only to registered UNI users who are over 18 and who live in countries
                  where adult content is legal. Also, please don’t put adult content in your profile
                  picture.
                </li>
                <li class="desc margin-bottom-4">
                  Share your creative work privately if: It depicts sexual acts, more extreme or
                  graphic nudity, highly offensive content, or graphically violent content. Although
                  this type of content is legal in many countries, we don’t allow it in projects
                  shared publicly on UNI — even behind the adult filter. You can store it privately.
                  If you want to share it privately, make sure you’re sharing it only with willing
                  recipients who are over 18 and who live in a country where the content you’re
                  sharing is legal.
                </li>
                <li class="desc margin-bottom-4">
                  Don’t upload It at all if: your content depicts minors in a sexual manner, or is
                  otherwise legally obscene or illegal. We also don’t allow content that clearly is
                  intended to promote hatred.
                </li>
              </ul>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.8">
              <p class="subtitle margin-0 font-bold">
                1.8 Create a team/organization page only if you are authorized to do so
              </p>
              <p class="margin-0 desc">
                Team pages may only be created by a current member of that team who is in good
                standing or a team’s legally authorized representative. Teams created for a brand,
                company, design firm, or educational institution must be created by a user with an
                email address that corresponds to that entity’s official domain (e.g, only someone
                with an ‘@companyname.com’ email address associated with their UNI account can
                create a Nike team page).
              </p>
              <p class="margin-0 desc">
                If you leave a team or company, you need to promptly transfer control of your team
                page to a current member of the team. We do not permit creating team pages as a way
                of expressing support for a team, or for parodying or critiquing a team, as they may
                mislead or confuse users. We also do not permit creating team pages for arbitrary
                groups or categories (for example: don’t create a team called “Top Designers on UNI”
                and invite your friends to it).
              </p>
              <p class="margin-0 desc">
                If you believe someone has created a team page that is impersonating your team or
                organization, please let us know by filling out our “Trademark infringement” form or
                by clicking on the “report” link you will find on that team page.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.9">
              <p class="subtitle margin-0 font-bold">
                1.9 Respect others’ rights and follow the law
              </p>
              <p class="margin-0 desc">
                We do not want UNI to be used for illegal activities or to violate the rights of
                others. Don’t use UNI’s services to commit fraud. Before sharing or using someone
                else’s copyrighted works, Trademarks, private information, or trade secrets, please
                make sure you have the legal right to do so.
              </p>
              <p class="margin-0 desc">
                Don’t present other people’s work as your own or overstate your own role in creating
                something. Don’t use other people’s Trademarks without permission. If you aren’t
                sure whether your use of someone else’s content or Trademark in your own work is
                legal, you can consult publicly available reference materials on copyrighting,
                payment, and Trademarks, the Chilling Effects website, or talk to an intellectual
                property attorney. If you want to report misuse of your own creative work or your
                own Trademark by one of our users, you can do that here.
              </p>
              <p class="margin-0 desc">
                If you have a contract, employment, or other dispute with a UNI user about the
                content or any kind of postings they have uploaded to our site, please click the
                “message” button on that user’s profile and resolve the issue with the user
                directly. We can’t moderate contracts, employment, or other disputes between our
                users and other third parties.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.10">
              <p class="subtitle margin-0 font-bold">1.10 Respect privacy</p>
              <p class="margin-0 desc">
                Don’t use UNI to reveal private information about other people. And don’t upload
                photographic portraits or nude images of identifiable people unless you have their
                permission.
              </p>
            </div>
            <div fxLayout="column" fxLayoutGap="16px" id="1.11">
              <p class="subtitle margin-0 font-bold">1.11 Respect UNI’s rights</p>
              <p class="margin-0 desc">
                Please don’t violate the rights of UNI. You can find more information about what
                this means in our Terms of Services, but put simply, please don’t use UNI’s services
                to wrongfully take data or information. It is not okay to suggest that you are
                affiliated with or endorsed by UNI when you are not, and it is not okay to violate
                UNI’s intellectual property rights.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-divider></mat-divider>
