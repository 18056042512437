<div class="main-container margin-auto" fxLayoutAlign="center stretch">
  <ng-container *ngIf="smallView; else largerView">
    <div class="sidenav-container">
      <div class="sidenav">
        <mat-expansion-panel class="expansion-panel mat-elevation-z0" [(expanded)]="expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p class="title margin-0 font-bold">Contents</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container *ngFor="let section of sections">
            <div class="margin-top-2">
              <ng-container *ngIf="section?.subsections; else defaultView">
                <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                  <mat-expansion-panel-header>
                    <mat-panel-title
                      class="margin-0 section sparro-grey-800"
                      (click)="toTop(section.id)">
                      {{ section.text }}
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <ng-container *ngFor="let subsection of section.subsections">
                    <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                      {{ subsection.text }}
                    </p>
                  </ng-container>
                </mat-expansion-panel>
              </ng-container>
              <ng-template #defaultView>
                <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                  {{ section.text }}
                </p>
              </ng-template>
            </div>
          </ng-container>
        </mat-expansion-panel>
      </div>
    </div>
  </ng-container>

  <ng-template #largerView>
    <div class="sidenav-container relative">
      <div class="sidenav">
        <p class="title margin-0 font-bold section-border">Contents</p>
        <ng-container *ngFor="let section of sections">
          <div class="section-border margin-top-2">
            <ng-container *ngIf="section?.subsections; else defaultView">
              <mat-expansion-panel class="expansion-panel mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title
                    class="margin-0 section sparro-grey-800"
                    (click)="toTop(section.id)">
                    {{ section.text }}
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let subsection of section.subsections">
                  <p class="margin-0 section sparro-grey-800" (click)="toTop(subsection.id)">
                    {{ subsection.text }}
                  </p>
                </ng-container>
              </mat-expansion-panel>
            </ng-container>
            <ng-template #defaultView>
              <p class="margin-0 section sparro-grey-800" (click)="toTop(section.id)">
                {{ section.text }}
              </p>
            </ng-template>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
  <div fxLayout="column" class="margin-9" fxLayoutGap="24px">
    <div class="padding-bottom-3 breadcrumb-section">
      <p class="margin-0 desc">
        <a href="https://uni.xyz/policy" class="no-decor-hover">Platform Policies</a> / Business /
        <span class="font-bold">Conduct policy</span>
      </p>
    </div>
    <div class="detail-section margin-top-9">
      <div fxLayout="column" fxLayoutGap="80px">
        <div fxLayout="column" fxLayoutGap="40px">
          <h1 class="margin-0">Conduct policy</h1>
          <p class="margin-0 desc">Effective from August 20, 2022</p>
        </div>
        <p class="margin-0 desc">
          <span class="font-bolder"
            >At UNI we are building a collaborative community of creative people.
          </span>
          UNI offers a place to upload your projects along with the thought process behind it and
          start discussions with other creative people who would be interested. This enables
          designers to share their work, get feedback and create new knowledge to make design more
          open and available to all.<br /><br />UNI works by users of UNI sharing their work, ideas
          and generating discussions on the UNI. Users of UNI helps in making UNI a great community
          for creative people. UNI is a place where which enables a fashion designer to work with a
          biologist together or a biotechnology scientist to critically analyze a product designer’s
          work. Its a place where all knowledge domains of art, design, and engineering co-exist for
          learning, imagination and innovation.<br /><br />In order to provide a space where
          everyone feels comfortable in sharing their work discuss their ideas and share among
          others, all UNI users should follow UNI key principles, UNI Policies, and UNI Terms of
          Service. We have created a list of Do’s and Dont’s for the users to make each UNI page
          useful and a great community for everyone.
        </p>

        <div fxLayout="column" fxLayoutGap="48px" id="1">
          <p class="margin-0 title font-bold">1. Do's</p>
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
            <i class="icon-info sparro-orange-800"></i>
            <i class="desc"
              >Our list of what to do on UNI to make each UNI page useful and a great community for
              everyone.</i
            >
          </div>
          <div fxLayout="column" fxLayoutGap="16px">
            <p class="margin-0 desc">You agree that you will:</p>
            <ul class="margin-0">
              <li class="desc margin-4">
                Comply with all applicable laws, including, without limitation, Privacy laws,
                intellectual property laws, anti-spam laws, export control laws, tax laws, and
                regulatory requirements.
              </li>
              <li class="desc margin-4">Provide accurate information to us and keep it updated.</li>
              <li class="desc margin-4">Use your real name on your profile.</li>
              <li class="desc margin-4">Use the Services professionally.</li>
              <li class="desc margin-4">
                Use UNI to provide constructive feedback to fellow users. If you do not agree with
                someone’s opinion/design, you may provide them with constructive critics rather than
                bullying/harassing other users.
              </li>
              <li class="desc margin-4">
                Use precise and concise language when discussing on the platform. Use proper grammar
                while putting your ideas, discussions, and project descriptions on the platform.
              </li>
              <li class="desc margin-4">
                Write clear answers to the discussions and attach any supporting image or file if
                you have, which can generate constructive conversations leading to innovative ideas.
              </li>
            </ul>
          </div>
        </div>
        <div fxLayout="column" fxLayoutGap="48px" id="2">
          <p class="margin-0 title font-bold">2. Don'ts</p>
          <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="start center" class="info-section">
            <i class="icon-info sparro-orange-800"></i>
            <i class="desc"
              >Our list of what not to do on UNI to avoid spam content keep the discussions
              meaningful.</i
            >
          </div>
          <div fxLayout="column" fxLayoutGap="16px">
            <p class="margin-0 desc">You agree that you will not:</p>
            <ul class="margin-0">
              <li class="desc margin-4">
                Act in an unlawful or unprofessional manner in connection with our Services,
                including being dishonest, abusive, or discriminatory.
              </li>
              <li class="desc margin-4">
                Post inaccurate, defamatory, obscene, shocking, hateful, threatening, or otherwise
                inappropriate content or airing personal grievances or disputes.
              </li>
              <li class="desc margin-4">
                Use an image that is not your likeness or a headshot photo for your profile.
              </li>
              <li class="desc margin-4">
                Create a false identity on UNI. The occasional creation of clearly fictional
                profiles by UNI or with its express permission in connection with a promotional
                campaign does not waive this obligation.
              </li>
              <li class="desc margin-4">
                Misrepresent your identity (e.g. by using a pseudonym), your current or previous
                positions, qualifications, works, or affiliations with a person or entity.
              </li>
              <li class="desc margin-4">
                Create a user profile for anyone other than yourself (a real person).
              </li>
              <li class="desc margin-4">Invite people you do not know to join your network.</li>
              <li class="desc margin-4">Use or attempt to use another’s account.</li>
              <li class="desc margin-4">Harass, abuse or harm another person.</li>
              <li class="desc margin-4">
                Send or post any unsolicited or unauthorized advertising, “junk mail,” “spam,”
                “chain letters,” “pyramid schemes,” or any form of solicitation unauthorized by UNI.
              </li>
              <li class="desc margin-4">
                Develop, support, or use software, devices, scripts, robots, or any other means or
                processes (including crawlers, browser plugins, and add-ons, or any other technology
                or manual work) to scrape the Services or otherwise copy profiles and other data
                from the Services without our permission.
              </li>
              <li class="desc margin-4">
                Bypass or circumvent any access controls or Service use limits (such as caps on
                keyword searches).
              </li>
              <li class="desc margin-4">
                Copy, use, disclose or distribute any information obtained from the Services,
                whether directly or through third parties (such as search engines), without the
                consent of UNI.
              </li>
              <li class="desc margin-4">
                Solicit email addresses or other personal information from Members you don’t know,
                without authorization.
              </li>
              <li class="desc margin-4">
                Use, disclose or distribute any data obtained in violation of this policy.
              </li>
              <li class="desc margin-4">
                Disclose information that you do not have the consent to disclose (such as
                confidential information of others (including your employer/team members)
              </li>
              <li class="desc margin-4">
                Violate the intellectual property rights of others, including copyrights, patents,
                Trademarks, trade secrets, or other proprietary rights. For example, do not copy or
                distribute (except through the available sharing functionality) the posts or other
                content of others without their permission, which they may give by posting under a
                Creative Commons license.
              </li>
              <li class="desc margin-4">
                Violate the intellectual property or other rights of UNI, including, without
                limitation, (i) copying or distributing our learning videos or other materials or
                (ii) copying or distributing our technology, unless it is released under open source
                licenses; (iii) using the word “UNI” or our logos in any business name, email, or
                URL
              </li>
              <li class="desc margin-4">
                Use UNI invitations to send messages to people who don’t know you or who are
                unlikely to recognize you as a known contact.
              </li>
              <li class="desc margin-4">
                Post anything that contains software viruses, worms, or any other harmful code.
              </li>
              <li class="desc margin-4">
                Manipulate identifiers in order to disguise the origin of any message or post
                transmitted through the Services.
              </li>
              <li class="desc margin-4">
                Create profiles or provide content that promotes escort services or prostitution.
              </li>
              <li class="desc margin-4">
                Create or operate a pyramid scheme, fraud, or other similar practice.
              </li>
              <li class="desc margin-4">
                Reverse engineer, decompile, disassemble, decipher or otherwise attempt to derive
                the source code for the Services or any related technology that is not open source.
              </li>
              <li class="desc margin-4">
                Imply or state that you are affiliated with or endorsed by UNI without our express
                consent (e.g., representing yourself as an accredited UNI trainer);
              </li>
              <li class="desc margin-4">
                Rent, lease, loan, trade, sell/re-sell access to the Services or related data.
              </li>
              <li class="desc margin-4">
                Sell, sponsor, or otherwise monetize any Service without UNI’s consent.
              </li>
              <li class="desc margin-4">
                Deep-link to our Services for any purpose other than to promote your profile or a
                Group on our Services, without our consent.
              </li>
              <li class="desc margin-4">
                Remove any copyright, Trademark, or other proprietary rights notices contained in or
                on our Service.
              </li>
              <li class="desc margin-4">
                Remove, cover or obscure any advertisement included on the Services.
              </li>
              <li class="desc margin-4">
                Use bots or other automated methods to access the Services, add or download
                contacts, send or redirect messages.
              </li>
              <li class="desc margin-4">
                Monitor the Services’ availability, performance, or functionality for any
                competitive purpose.
              </li>
              <li class="desc margin-4">
                Engage in “framing,” “mirroring,” or otherwise simulating the appearance or function
                of the Services.
              </li>
              <li class="desc margin-4">
                Overlaying or otherwise modifying the Services or their appearance.
              </li>
              <li class="desc margin-4">
                Access the Services except through the interfaces expressly provided by UNI, such as
                its mobile applications competitions.
              </li>
              <li class="desc margin-4">Use a Service for tasks that it is not intended for.</li>
              <li class="desc margin-4">Override any security feature of the Services.</li>
              <li class="desc margin-4">
                Interfere with the operation of, or place an unreasonable load on, the Services
                (e.g., spam, denial of service attack, viruses, gaming algorithms); and/or
              </li>
              <li class="desc margin-4">
                Violate the Community Guidelines or any additional terms concerning a specific
                Service that are provided when you sign up for or start using such Service.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<mat-divider></mat-divider>
