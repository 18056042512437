import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { BREAKPOINTS } from '@sparro/app/core/config/constants.config';
import { MetaDefinition } from '@angular/platform-browser';
import { MetaService } from '@sparro/app/core/helpers/meta/meta.service';

@Component({
  selector: 'app-community-guidlines',
  templateUrl: './community-guidlines.component.html',
  styleUrls: ['./community-guidlines.component.scss']
})
export class CommunityGuidlinesComponent implements OnInit {
  smallView: boolean = false;
  expanded: boolean = false;

  sections = [
    {
      text: `Rules for making great community`,
      id: `1`,
      subsections: [
        {
          text: `Be real`,
          id: `1.1`
        },
        {
          text: `Be respectful`,
          id: `1.2`
        },
        {
          text: `Upload your own work`,
          id: `1.3`
        },
        {
          text: `Constructive criticism`,
          id: `1.4`
        },
        {
          text: `Stay on-topic`,
          id: `1.5`
        },
        {
          text: `Be Nice`,
          id: `1.6`
        },
        {
          text: `Put creative work not suitable for adults behind the filter`,
          id: `1.7`
        },
        {
          text: `Create a team/organization page only if you are authorized to do so`,
          id: `1.8`
        },
        {
          text: `Respect others’ rights and follow the law`,
          id: `1.9`
        },
        {
          text: `Respect privacy`,
          id: `1.10`
        },
        {
          text: `Respect UNI’s rights`,
          id: `1.11`
        }
      ]
    }
  ];

  constructor(public breakpointObserver: BreakpointObserver, public metaService: MetaService) {}

  ngOnInit(): void {
    this.initializeBreakpoints();
    this.updateMetaInfo();
  }

  initializeBreakpoints(): void {
    this.breakpointObserver.observe([BREAKPOINTS.below.md]).subscribe(result => {
      this.smallView = Boolean(result.breakpoints[BREAKPOINTS.below.md]);
    });
  }

  toTop(id: string) {
    var element = document.getElementById(id);
    var headerOffset = this.smallView ? 250 : 150;
    var elementPosition = element.getBoundingClientRect().top;
    var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
    this.expanded = false;
  }

  updateMetaInfo(): void {
    const title = 'Community guidelines - UNI';
    const description = `UNI is a company that aims to put its users first when it comes to making important decisions.`;
    const cover = `https://uni-common-assets.s3.us-east-1.amazonaws.com/logo/uni/uni_logo_393x393.svg`;
    const metaInfo: MetaDefinition[] = [
      { name: 'title', content: title },
      { name: 'description', content: description },
      { name: 'url', content: window.location.href },
      { name: 'image', content: cover },
      { name: 'twitter:card', content: 'summary_large_image' },
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:url', content: window.location.href },
      { name: 'twitter:image', content: cover },
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: window.location.href },
      { property: 'og:image', content: cover },
      { property: 'og:image:alt', content: 'UNI- Design competitions' },
      { property: 'og:type', content: 'article' }
    ];
    metaInfo.forEach(meta => {
      this.metaService.updateMetaInfo(meta);
    });
    this.metaService.updateMeta(metaInfo);
    this.metaService.updateTitle(title);
    this.metaService.createCanonicalURL();
  }
}
