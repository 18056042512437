import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AcceptableUseComponent } from './acceptable-use/acceptable-use.component';
import { CommunityGuidlinesComponent } from './community-guidlines/community-guidlines.component';
import { ConductComponent } from './conduct/conduct.component';

const routes: Routes = [
  {
    path: 'acceptable-use',
    component: AcceptableUseComponent
  },
  {
    path: 'guidlines',
    component: CommunityGuidlinesComponent
  },
  {
    path: 'conduct',
    component: ConductComponent
  },
  {
    path: '**',
    redirectTo: '/policy'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommunityRoutingModule {}
